import ApiService from "@/service/ApiService";

export default function useRegister() {
  const Register = async (data) => {
    return await ApiService.post("register", data);
  };

  const UpdateUser = async (data) => {
    return await ApiService.put("edit_user/" + data.id_line, data);
  };

  return {
    Register,
    UpdateUser,
  };
}
