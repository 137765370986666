<template>
  <div class="bg-home vh100">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <img src="~@/assets/header.png" class="mt-0">

    <div class="container">
      <div class="title1 text-white text-center">ยินดีต้อนรับสู LINE OA</div>
      <div class="title2 text-white text-center">ศิษเก่าโรงเรียนสตรีนนทบุรี</div>
      <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
        ref="formContainer" novalidate="novalidate">
      
        <div class="register-box mt-2 ms-3 me-3 text-start pb-2 pt-2">
        
          <Field type="hidden" name="id_line" v-model="accesstoken" />
          <div class="row mb-2 text-start ps-4 pe-4">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6">คำนำหน้า</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field as="select" name="fn" class="form-control  form-control-solid" @change="checkFn($event)"
                v-model="data_register.fn">
                <option value="" selected disabled>โปรดเลือก</option>
                <option value="นาย">นาย</option>
                <option value="นาง">นาง</option>
                <option value="นางสาว">นางสาว</option>
                <option value="อื่นๆ">อื่นๆ</option>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="fn" />
                </div>
              </div>
            </div>
            <!--end::Col-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
              <Field type="text" name="fn_other" class="form-control  form-control-solid" placeholder="คำนำหน้า"
                v-model="data_register.fn_other" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="fn_other" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>

          <div class="row mb-2 text-start ps-4 pe-4 ">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อ</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field type="text" name="firstname" class="form-control  form-control-solid" placeholder="ชื่อ"
                v-model="data_register.firstname" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="firstname" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-2 text-start ps-4 pe-4 ">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6">นามสกุล</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field type="text" name="lastname" class="form-control  form-control-solid" placeholder="นามสกุล"
                v-model="data_register.lastname" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="lastname" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>


          <div class="row mb-2 text-start ps-4 pe-4 ">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6">เพศ</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <div class="row mt-2 justify-content-start">
                <div class="col-4">
                  <label class="form-check form-check-custom form-check-solid">
                    <Field type="radio" name="gender" class="form-check-input" value="ชาย"
                      v-model="data_register.gender" />

                    <span class="form-check-label gender" for="kt_roles_select_all">ชาย <img :src="iconMale"
                        class="icon" alt="" /></span>
                  </label>
                </div>
                <div class="col-4">

                  <label class="form-check form-check-custom form-check-solid">
                    <Field type="radio" name="gender" class="form-check-input" value="หญิง"
                      v-model="data_register.gender" />
                    <span class="form-check-label gender" for="kt_roles_select_all">หญิง <img :src="iconFemale"
                        class="icon" alt="" /></span>
                  </label>
                </div>
              </div>
              <div class="fv-plugins-message-container mt-3">
                <div class="fv-help-block">
                  <ErrorMessage name="gender" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>

          <div class="row mb-2 text-start ps-4 pe-4 ">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6">เบอร์โทรศัพท์</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field type="text" name="tel" class="form-control  form-control-solid" placeholder="เบอร์โทรศัพท์"
                v-model="data_register.tel" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="tel" />
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2 text-start ps-4 pe-4 ">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6">Email</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field type="text" name="other_detail" class="form-control  form-control-solid" placeholder="Email"
                v-model="data_register.other_detail" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="other_detail" />
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2 ps-4 pe-4">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">ศิษย์เก่า/ปัจจุบัน</label>
            <div class="col-lg-8 fv-row">
              <Field name="end_grade" class="form-control  form-control-solid" as="select" v-model="data_register.start_year" @change="setStudentType($event)">
                <option value="" selected disabled>โปรดเลือก</option>
                <option value="m0">ศิษย์เก่า</option>
                <option value="m6">ศิษย์ปัจจุบัน</option>
                <option value="m-1">อื่นๆ</option>
               
                <!-- <option v-for="(n, index) in setRange()" :key="index" :value="n">
                  {{ n }}</option> -->
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="end_grade" />
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row mb-2 ps-4 pe-4" v-if="StudentType == 'ศิษย์เก่า'">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">ระดับชั้นที่จบการรศึกษา</label>
            <div class="col-lg-8 fv-row">
              <Field name="start_year" class="form-control  form-control-solid" as="select"
                v-model="data_register.start_year">
                <option value="" selected disabled>โปรดเลือก</option>
                <option value="m3">มัธยมศึกษาปีที่ 3</option>
                <option value="m6">มัธยมศึกษาปีที่ 6</option>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="start_year" />
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2 ps-4 pe-4" v-if="StudentType == 'ศิษย์เก่า'">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">ปีที่จบการศึกษา</label>
            <div class="col-lg-8 fv-row">
              <Field name="end_year" class="form-control  form-control-solid" as="select"
                v-model="data_register.end_year">
                <option value="" selected disabled>โปรดเลือก</option>
                <option v-for="(n, index) in setRange()" :key="index" :value="n">
                  {{ n }}</option>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="end_year" />
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <div class="row mb-2 justify-content-center ps-4 pe-4">
          <button type="button" @click="back" class="submit-button mt-2 me-3">
            ย้อนกลับ
          </button>
          <button type="submit" class="submit-button mt-2">
            แก้ไขข้อมูล
          </button>
        </div>


      </Form>
    </div>
  </div>
</template>

<script>
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import useRegister from "@/service/api/register";
import Loading from "vue-loading-overlay";
import liff from "@line/liff";
import moment from "moment";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import useSweetalert from "@/service/sweetalert2";
import json from "@/service/DataStore.json";
import * as Yup from "yup";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {

    const items = ref(json);
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { UpdateUser } = useRegister();
    let isLoading = ref(false);
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;
    const accesstoken = store.getters.getAccessToken.accesstoken;
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const register = Yup.object().shape({
      fn: Yup.string().required("กรุณาเลือก คำนำหน้า").label("คำนำหน้า"),
      fn_other: Yup.string()
        .when("fn", {
          is: (fn) => fn === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก คำนำหน้า"),
        })
        .label("คำนำหน้า"),
      firstname: Yup.string().required("กรุณากรอก ชื่อ").label("ชื่อ"),
      lastname: Yup.string().required("กรุณากรอก นามสกุล").label("นามสกุล"),
      gender: Yup.string().required("กรุณาเลือก เพศ").label("เพศ"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
      // numberhome: Yup.string()
      //   .required("กรุณากรอก บ้านเลขที่")
      //   .label("บ้านเลขที่"),
      // moo: Yup.string().label("หมู่"),
      // alleyway: Yup.string().label("ตรอก/ซอย"),
      // road: Yup.string().label("ถนน"),
      // province: Yup.string().required("กรุณาเลือก จังหวัด").label("จังหวัด"),
      // amphoe: Yup.string().required("กรุณาเลือก อำเภอ/เขต").label("อำเภอ/เขต"),
      // district: Yup.string()
      //   .required("กรุณาเลือก ตำบล/แขวง")
      //   .label("ตำบล/แขวง"),
      // zipcode: Yup.string()
      //   .required("กรุณากรอก รหัสไปรษณีย์")
      //   .label("รหัสไปรษณีย์"),
      end_grade: Yup.string()
        .required("ความเป็นศิษย์").label("ความเป็นศิษย์"),
      // end_year: Yup.string()
      //   .when("end_grade", {
      //     is: (end_grade) => end_grade == 'ศิษย์เก่า',
      //     then: Yup.string().required("กรุณาเลือกปีที่จบการศึกษา")
      //   }),
      // start_year: Yup.string()
      //   .when("end_grade", {
      //     is: (end_grade) => end_grade == 'ศิษย์เก่า',
      //     then: Yup.string().required("กรุณาเลือกระดับชั้นที่จบการศึกษา")
      //   }),
      other_detail: Yup.string().required("กรุณากรอก Email").label("Email")
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const setRange = () => {
      let current_year = moment().year() + 543;
      let value = [];
      let i;
      for (i = current_year; i >= 2400; i--) {
        value.push(i);
      }
      return value;
    };

    const uniqueProvince = computed(() => {
      return items.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.province]: current,
        });
      }, {});
    });

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter(
        (item) => item.province === data_register.province
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter(
        (item) => item.amphoe === data_register.amphoe
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });

    function setZipcode(val) {
      let result = val;

      let data = items.value.filter(
        (item) =>
          item.district === result && item.amphoe === data_register.amphoe
      );
      data_register.zipcode = data[0].zipcode;
    }


    const onSubmitRegister = (values) => {
      store.dispatch(SET_REGISTER, values);

      Sconfirm("ยืนยัน การแก้ไขข้อมูล", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await UpdateUser(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("แก้ไขข้อมูลสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    const back = () => {
      router.push({ name: "Profile" });
    };

    const StudentType = (data_register.start_year == "m-1" ? "อื่นๆ" : data_register.start_year == "m0" ? "ศิษย์ปัจจุบัน" : "ศิษย์เก่า")
    const student_type = ref(false)

    const setStudentType = () => {
      if (StudentType == "ศิษย์ปัจจุบัน") {
        data_register.start_year = "m0"
        data_register.end_year = "2566"
      }
      if (StudentType == "อื่นๆ") {
        data_register.start_year = "m-1"
        data_register.end_year = "2566"
      }
      return (StudentType === "ศิษย์เก่า" ? student_type.value = true : student_type.value = false)
    }

    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    return {
      StudentType,
      student_type,
      setStudentType,
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      data_register,
      setRange,
      uniqueProvince,
      uniqueAmphoe,
      uniqueDistrict,
      setZipcode,
      color,
      isLoading,
      iconMale,
      iconFemale,
      accesstoken,
      back
    };
  },
});

</script>

<style scoped>
.icon {
  width: 20px !important;
  /* adjust the width of the image */
  height: 20px !important;
  /* adjust the height of the image */
  margin-right: 5px;
  /* add some space between the image and the label text */
}

.line {
  border-top: 1px solid #707070;
  width: 100%;
  box-shadow: -1px 5px 29px -3px rgba(112, 112, 112, 0.9);
}

.header {
  background-image: url("~@/assets/header.png");
  object-fit: cover;
  height: 150px;
  border: none;
  padding: 0;
}

.title1 {
  font-size: 16px;
  font-weight: medium;
}

.title2 {
  font-size: 25px;
  font-weight: medium;
}

.register-box {
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  height: auto;
  height: auto;
  text-align: center;
  border-radius: 25px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.submit-button {
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 150px;
  border-radius: 25px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.submit-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.submit-button:hover {
  transform: translateY(-1px);
}

.submit-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.bg-home {
  background-color: var(--color);
  overflow-y: scroll;
  padding-bottom: 20px;
}

.bg-home img {
  position: relative;
  object-fit: cover;
  width: 100%;
  border: none;
  height: auto;
  padding-top: 0;

}
</style>
