<template>
  <div class="bg-home vh100">
    <img src="~@/assets/header.png" class="mt-0">


    <div class="container">
      <div class="title1 text-white text-center">ยินดีต้อนรับสู LINE OA</div>
      <div class="title2 text-white text-center">ศิษเก่าโรงเรียนสตรีนนทบุรี</div>

      <div class="card mt-2 ms-3 me-3">
        <div class="card-header border-0">
          <i @click="edit" class="bi bi-pencil-square icon"></i>
          <div class="profile-picture">
            <img :src="ProfireLine.pictureUrl">
          </div>
          <div>
            <h4>คุณ {{ Profire.firstname }} {{ Profire.lastname }}</h4>
            <p>{{ (Profire.start_year == "m0" ? "ศิษย์ปัจจุบัน" : Profire.start_year == "m-1" ? "อื่นๆ" : "ศิษย์เก่า") }}</p>
          </div>
        </div>
        <div class="card-footer border-0 d-flex flex-wrap justify-content-between align-items-end">
          <div>
            <p>เบอร์โทรศัพท์ : {{ Profire.tel }}</p>
          </div>
          <div class="logo-picture ">
            <img src="~@/assets/logo.png">
          </div>
        </div>
      </div>

      <div class="row mb-2 justify-content-end">
        <button type="button" @click="close" class="submit-button me-4 mt-3">
          กลับไปหน้าไลน์
        </button>
      </div>







      <center class="mt-5 text-white" v-if = "Profire.checkin_tables" >
        <h1>เลขโต๊ะ </h1>
        <b style="font-size:8rem"> {{ Profire.checkin_tables.table_code }} </b>
      </center>





    </div>

  </div>
</template>

<script>
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  setup() {
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    return {

      fn_status,
      iconMale,
      iconFemale,
      ProfireLine,
      Profire,
      edit,
      close
    };
  },
});

</script>

<style scoped>
.header {
  background-image: url("~@/assets/header.png");
  object-fit: cover;
  height: 150px;
  border: none;
  padding: 0;
}

.title1 {
  font-size: 16px;
  font-weight: medium;
}

.title2 {
  font-size: 25px;
  font-weight: medium;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #4E6629;
}


.card {
  color: #4E6629;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  border-radius: 25px;
  padding: 5px;
  background-image: url("~@/assets/card-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.card-header {
  margin-top: 30px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.profile-picture img {
  width: 100%;
}

.card-footer {
  border: none;
  background-color: transparent;
  justify-content: end;
}

.logo-picture {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.logo-picture img {
  width: 100%;
}

.submit-button {
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 150px;
  border-radius: 25px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.submit-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.submit-button:hover {
  transform: translateY(-1px);
}

.submit-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.bg-home {
  background-color: var(--color);
  overflow-y: scroll;
  padding-bottom: 20px;
}

.bg-home img {
  position: relative;
  object-fit: cover;
  width: 100%;
  border: none;
  height: auto;
  padding-top: 0;

}
</style>
